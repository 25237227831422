const Roadmap = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width ? props.width : 20.566}
    height={props.height ? props.height : 28}
    viewBox='0 0 20.566 28'
    {...props}
  >
    <path
      id='Roadmap'
      d='M14.985,25.419a2.692,2.692,0,0,1,2.791-2.58,2.589,2.589,0,1,1,0,5.162A2.693,2.693,0,0,1,14.985,25.419ZM0,25.419a2.692,2.692,0,0,1,2.791-2.58,2.589,2.589,0,1,1,0,5.162A2.692,2.692,0,0,1,0,25.419Zm6.96.568a.568.568,0,1,1,0-1.135h6.647a.568.568,0,1,1,0,1.135Zm10.395-4.149V11.825H1.192l6.4-5.176L1.2,1.456H17.355V.567a.6.6,0,0,1,1.2,0V21.838a.6.6,0,0,1-1.2,0Z'
      fill='#fff'
    />
  </svg>
);

export default Roadmap;
